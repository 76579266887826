app.service('accessoryCrossselling', function (lazyLoad, pageConfig, modal, productDetailsModal, cartManager, log) {
	let $self;
	let config = {};
	let productsInCart = {};
	let productId;
	let $menu, $list, $menuTabsBox;
	let menuTabsData = [], overflowedMenuElementsCount = 0;

	function init() {

		$self = $('#accessory-crossselling');

		if (!$self.length) return;
		config = pageConfig.getData('precart');
		productId = config.productId;

		if (!productId) {
			return onError('productId is not defined.');
		}

		productsInCart = config.productsInCart;

		return fetchAccessories()
			.then(html => {
				render(html);
				$menu = $('.js-accessory-group', $self);
				$list = $('.accessory-list', $self);
				$menuTabsBox = $('.accessory-groups ul', $self);
				setMenuTabsInfo();
				setAccessoryListOverflowInfo();
				setEvents();
				setHeight($list.first());
				lazyLoad.update();
				checkProductsInCart();
			})
			.fail(onError)
			.always(removeLoader);
	}

	function checkProductsInCart() {

		$('.js-add-accessory-to-cart').each(function () {
			let $element = $(this);
			let id = $element.data('id') / 1
			let checked = $.inArray(id, productsInCart) > -1;
			if (checked) {
				$element.addClass('added-to-cart');
			} else {
				$element.removeClass('added-to-cart');
			}
		});
		
	}

	function fetchAccessories() {
		return $.ajax({
			url: '/accessory-cross-selling.ltr',
			data: {
				productId
			}
		});
	}

	function render(html) {
		$self.html(html);
	}
	function setHeight($activeList) {
		let $content = $activeList.find('.js-accessory-content');
		let minHeight = 0;
		$content.each(function () {
			minHeight = Math.max(minHeight, $(this).height());
		});
		$content.css('height', minHeight);
	}
	function setMenuTabsInfo() {
		const scroll = $menuTabsBox.attr('data-scroll') / 1 || 0;
		const boxWidth = $menuTabsBox[0].offsetWidth;
		menuTabsData = [];
		overflowedMenuElementsCount = 0;
		$menu.each(function(index, el){
			menuTabsData.push({
				offsetLeft: el.offsetLeft,
				width: el.offsetWidth,
				offsetToRight: el.offsetLeft + el.offsetWidth
			})
			if (boxWidth < (el.offsetLeft + el.offsetWidth)) {
				overflowedMenuElementsCount++;
			}
		});
		$menuTabsBox.attr('data-overflowed-elements', overflowedMenuElementsCount > scroll ? overflowedMenuElementsCount - scroll : 0);
	}

	function slideMenuTabs(direction) { 
		if(overflowedMenuElementsCount >= 0) {
			let scroll = $menuTabsBox.attr('data-scroll') / 1 || 0;
			direction === 'right' ? scroll++ : scroll--;
			$menuTabsBox.attr('data-overflowed-elements', overflowedMenuElementsCount > scroll ? overflowedMenuElementsCount - scroll : 0)
			if (scroll) {
				$menuTabsBox.attr('data-scroll', scroll);
				$menuTabsBox.css('transform' , 'translateX('+ (-1 * menuTabsData[scroll].offsetLeft) + 'px)');
			} else {
				$menuTabsBox.removeAttr('data-scroll');
				$menuTabsBox.css('transform' , 'translateX(0px)');
			}
		}
	}

	function setAccessoryListOverflowInfo() {
		const $activeList = $self.find('.accessory-list.active');
		const $accessoryBox = $activeList.find('.accessory-box');

		const scroll = $activeList.attr('data-scroll') / 1 || 0;
		const boxWidth = $activeList[0].offsetWidth;
		const boxItemsCount = $accessoryBox[0].children.length;
		const boxItemWidth = $accessoryBox.find('.accessory-item').outerWidth(true); // width with margin
		const boxItemsOutsideView = Math.round((boxItemsCount * boxItemWidth - boxWidth) / boxItemWidth);

		$activeList.attr('data-overflowed-elements', boxItemsOutsideView > scroll ? boxItemsOutsideView - scroll : 0);
	}

	function slideAccessory(direction) { 
		const $activeList = $self.find('.accessory-list.active');
		const $accessoryBox = $activeList.find('.accessory-box');

		const boxWidth = $accessoryBox[0].offsetWidth;
		const boxItemsCount = $accessoryBox[0].children.length;
		const boxItemWidth = $accessoryBox.find('.accessory-item').outerWidth(true); // width with margin
		const boxItemsOutsideView = Math.round((boxItemsCount * boxItemWidth - boxWidth) / boxItemWidth);

		if(boxItemsOutsideView >= 0) {
			let scroll = $activeList.attr('data-scroll') / 1 || 0;
			direction === 'right' ? scroll++ : scroll--;
			$activeList.attr('data-overflowed-elements', boxItemsOutsideView > scroll ? boxItemsOutsideView - scroll : 0);
			if (scroll) {
				$activeList.attr('data-scroll', scroll);
				$accessoryBox.css('transform' , 'translateX('+ (-1 * scroll * boxItemWidth) + 'px)');
				lazyLoad.update();
			} else {
				$activeList.removeAttr('data-scroll');
				$accessoryBox.css('transform' , 'translateX(0px)');
			}
		}
	}

	function setEvents() {

		$self.on('click', '.js-k0-product-details', function (event) { 
			let product = $(this).data('id') / 1;
			let productPlu = $(this).data('plu');
			productDetailsModal.open({
				productId: product,
				productPlu: productPlu,
				parentId: config.productId,
				parentPlu: config.productPlu,
				callbackAdded: function () { 
					modal.close();
					productsInCart = [product, ...productsInCart];
					checkProductsInCart();
				}
			});
			event.stopPropagation();
			event.preventDefault();
		}).on('click', '.js-accessory-group', function () {
			if (!$(this).is('.active')) {
				let index = $(this).index();
				$menu.removeClass('active prev-active');
				$menu.eq(index).addClass('active').prev().addClass('prev-active');
				$list.removeClass('active').removeAttr('data-scroll');
				$list.find('.accessory-box').css('transform','translateX(0)');
				let $activeList = $list.eq(index);
				$activeList.addClass('active');
				setAccessoryListOverflowInfo();
				lazyLoad.update();
				setHeight($activeList);
			}
		}).on('touchend', '.accessory-list', function () {
			lazyLoad.update();
		}).on('click', '.js-slide-accessory-right', function () {
			slideAccessory('right');
		}).on('click', '.js-slide-accessory-left', function () {
			slideAccessory('left');
		}).on('click', '.js-slide-menu-tabs-right', function () {
			slideMenuTabs('right')
		}).on('click', '.js-slide-menu-tabs-left', function () {
			slideMenuTabs('left')
		}).on('click', '.js-add-accessory-to-cart', function () {
			let checked = $(this).is('.added-to-cart');
			let id = $(this).data('id')/1;
			let accessoryPlu = $(this).data('plu');
			if (!checked) {
				cartManager.simpleAddProduct({
						toCart: true,
						addedFrom: 'TO_CART',
						productId: id,
						instalment: config.instalment,
						instalmentNumber: config.instalmentNumber
					}, () => {
						log.recommendationsForAWS({
							productPlu: $(this).data('plu'),
							eventCategory: 'K0',
							eventAction: 'Dodanie akcesoria'
						});
						cartManager.refresh();
					}, {
						id: 'to_cart',
						f03: 'cart_step_0',
						f04: accessoryPlu,
						f05: 'akc',
						f06: config.productPlu,
						f07: 'accessory_card',
						f08: 'button',
						f09: 'new',
						f10: config.isInstalmentPrice ? 'installment' : 'cash'
					});

				productsInCart = [id, ...productsInCart];
			} else {
				cartManager.removeProduct({
					productId: id
				}, function () {
					cartManager.refresh();
				},{
					id: 'remove_cart',
					f03: 'cart_step_0',
					f04: accessoryPlu,
					f05: 'akc',
					f06: config.productPlu,
					f07: 'accessory_card',
					f08: 'button',
					f09: 'new',
					f10: config.isInstalmentPrice ? 'installment' : 'cash',
					f11: ''
				});

				productsInCart = productsInCart.filter(i => i !== id);
			}

			checkProductsInCart();
		});
		$(window).on('resize',function(){
			setMenuTabsInfo();
			setAccessoryListOverflowInfo();
		});
	}

	function onError(e) {
		$self.html('');
		console.error(e);
	}

	function removeLoader() {
		$self.removeClass('loading');
	}

	return {
		init
	};
});