app.service('search',function(searchHistory, loading, modal, cookie, front){

	function emptyKeyword(){							// do poprawy
		modal.alert('Proszę wpisać szukaną frazę');
	}

	function goToSearchPage(value, category){
		var url=document.location.host;
		loading.start();
		if(document.location.protocol !== ''){
			url = document.location.protocol+ '//' + url;
		}
		if (category!='0' && category!==undefined){
			url +='/search';
			url += '/' + category;
		}else{
			
		let testAB = cookie.get('searchTestAB');
		if(testAB === 'G'){
			url += '/search2';
		} else if(testAB === 'P'){
			url += '/search3';
		} else {
			url +='/search';
		}
		}
		url += '.bhtml?keyword=' + encodeURIComponent(value);
		if(front.katalog){
			const data = {
				productsCriteria: $("#search-criteria").val()
			};
			$.ajax({
				url: '/wc-change-products-criteria.ltr',
				type: 'POST',
				data: data,
				success: function () {
					loading.redirect(url);
				},
				error: function () {
					loading.stop();
					modal.alert('Coś poszło nie tak, spróbuj ponownie');
				}
			})
		} else {
			loading.redirect(url)
		}
	}
	function search(q) {
		var value = $.trim(q);
		if (value.length < 1){
			emptyKeyword();
			return;
		}
		if (searchHistory.enabled() && value!=='') {
			var obj = {};
			obj[value] = {link: ""};
			searchHistory.add(obj);
		}
		goToSearchPage(value,$('#search-category').val());
	}
	
	return {
		search
	};
});
