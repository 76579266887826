app.service('sufficientOutletCategory', function(
	modal,
	lazyLoad,
	loading,
	pageConfig,
	stackedModal,
	sufficientCarouselPreview,
	modalDelivery
) {
	let $addToCartButton;
	let previewModal;
	let $modal;

	function loadSingleProductForAccountPreview(data) {
		return $.ajax({
			url: '/web-cached/individual-outlet-offer-account-preview.ltr',
			data
		});
	}

	function loadSingleProductPreview(data) {
		return $.ajax({
			url: '/web-cached/product-detail/individual-outlet-offer-preview.ltr',
			data
		});
	}

	function initPreviewGallery(startIndex) {
		sufficientCarouselPreview.init(startIndex);
	}

	function handleAcceptanceCheckboxClick() {
		const checked = $(this).prop('checked');
		const $acceptanceBox = $(this).closest('.acceptance-box');

		if (checked) {
			$addToCartButton.removeClass('is-disabled');
			$acceptanceBox.removeClass('show-error');
		} else {
			$addToCartButton.addClass('is-disabled');
			$acceptanceBox.addClass('show-error');
		}
	}

	function handleAddToCartClick() {
		if ($addToCartButton.hasClass('is-disabled')) {
			$(this)
				.closest('.sufficient-product-control')
				.find('.acceptance-box')
				.addClass('show-error');
			return false;
		}
	}

	function setModalEvents() {
		const $productDescription = $modal.find('.sufficient-product-description');

		$modal
			.on('click', '.js-read-more', e => {
				e.preventDefault();
				$productDescription.addClass('show-full-description');
			})
			.on('click', '.js-read-less', e => {
				e.preventDefault();
				$productDescription.removeClass('show-full-description');
			})
			.on('click', '.js-acceptance-box', handleAcceptanceCheckboxClick)
			.on('click', '.js-add-to-cart-individual-offer', handleAddToCartClick)
			.on('click', '.js-show-next, .js-show-prev', handleChangeModalClick)
			.on('click', '.js-outlet-modal-home-delivery', openDeliveryModal);
	}

	function setModalButtons() {
		const $control = $modal.find('.sufficient-product-control');
		const huCode = '' + $control.data('hu-code');
		const huCodesInBasket = getHuCodesInBasket();
		if (huCodesInBasket.indexOf(huCode) > -1) {
			$control.addClass('is-active');
		}
	}

	function handleModalOpen(startIndex = 0) {
		$addToCartButton = $modal.find('.add-to-cart');
		initPreviewGallery(startIndex);
		setModalButtons();
		setModalEvents();
	}

	function showPreviewModal(content, position) {
		previewModal = modal.generate({
			id: 'sufficient-product-preview',
			cssClass: 'sufficient-product-preview',
			callbackOpen: function() {
				$modal = this.$modal;
				handleModalOpen(position);
			},
			content
		});
	}

	function showErrorModal(xhr) {
		const errorMessage = `${xhr.status}: ${xhr.statusText}`;

		modal.alert(errorMessage);
	}

	function responseHasErrors(html) {
		return $(html).filter('.error-message').length;
	}

	function showResponseModal(html, position) {
		if (responseHasErrors(html)) {
			modal.alert(html);
		} else {
			showPreviewModal(html, position);
		}
	}

	function showIndividualOfferForAccountPreview(data) {
		loadSingleProductForAccountPreview(data)
			.then(html => showResponseModal(html, 0))
			.fail(showErrorModal);
	}

	function showIndividualOffer(outletProductId, position) {
		const data = { outletProductId };
		loading.start();

		loadSingleProductPreview(data)
			.then((html) => {
				showResponseModal(html, position);
				stackedModal.push(previewModal);
			})
			.fail(showErrorModal)
			.always(loading.stop);
	}

	function changeIndividualOffer(outletProductId) {
		const data = { outletProductId };
		loading.start();

		loadSingleProductPreview(data)
			.then((html) => {
				showResponseModal(html);
				stackedModal.replaceLastModal(previewModal);
			})
			.fail(showErrorModal)
			.always(loading.stop);
	}

	function getHuCodesInBasket() {
		const { items } = pageConfig.getData('basket');
		return items.map(item => item.huCode);
	}

	function handleChangeModalClick() {
		const outletProductId = $(this).data('outlet-product-id');
		changeIndividualOffer(outletProductId);
	}

	function handleOutletProductPreviewClick(e) {
		e.preventDefault();

		const data = {
			huCode: $(this).data('code')
		};

		showIndividualOfferForAccountPreview(data);
	}

	function openDeliveryModal() {
		const outletCategoryId = $(this).data('outletCategory');
		const productId = pageConfig.getData('productCard').id;
		modalDelivery.showModal({
			productId,
			outletCategoryId
		});
		UA.pageInteraction('CTA', 'Sprawdź termin dostawy');
	}

	function setEvents() {
		$('body')
			.on('click', '.js-outlet-product-preview', handleOutletProductPreviewClick)
	}

	function init() {
		setEvents();
	}

	return {
		init,
		showIndividualOffer
	};
});
