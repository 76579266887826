app.service('sufficientCarouselPreview', function() {
	let carousel;
	let previewPhotoSlider;

	let $zoomControl;

	let zoomEnabled = false;

	function onCarouselInit(startIndex) {
		carousel = this;
		previewPhotoSlider = carousel.asNavFor;

		carousel.getSlide(startIndex).addClass('active');

		// show arrows
		if (carousel.innerElements.length > 5) {
			carousel.selector.parent().addClass('show-arrow-nav');
		}

		setEvents();

		previewPhotoSlider
			.getSlide()
			.find('img')
			.on('load', updateZoomControl);
	}

	function resetZoom() {
		const target = previewPhotoSlider.getSlide();

		target.trigger('zoom.destroy');

		zoomEnabled = false;
	}

	function isImageScalable() {
		const currentImage = previewPhotoSlider.getSlide().find('img');

		if (!currentImage.length) return false;

		return currentImage.get(0).naturalWidth > currentImage.width();
	}

	function updateZoomControl() {
		if (isImageScalable()) {
			$zoomControl.addClass('is-visible');
		} else {
			$zoomControl.removeClass('is-visible');
		}
	}

	function handleCarouselThumbnailClick() {
		$(this)
			.addClass('active')
			.siblings()
			.removeClass('active');
	}

	function onPreviewPhotoSliderChange() {
		updateZoomControl();

		resetZoom();

		$zoomControl.find('.zoom-in').removeClass('is-active');
	}

	function handleZoomInClick() {
		if (zoomEnabled) return false;

		$(this).addClass('is-active');

		zoomEnabled = true;

		const target = previewPhotoSlider.getSlide();

		target.zoom({
			callback: function() {
				$(this).fadeTo(120, 1);
			}
		});
	}

	function setEvents() {
		previewPhotoSlider.config.onChange = onPreviewPhotoSliderChange;

		$zoomControl
			.on('click', '.js-zoom-in', handleZoomInClick)
			.on('click', '.js-zoom-out', resetZoom)
			.on('mousedown', '.js-zoom-out', () =>
				$zoomControl.find('.zoom-in').removeClass('is-active')
			);

		carousel.selector
			.parent()
			.on('click', '.js-carousel-next', () => carousel.next())
			.on('click', '.js-carousel-prev', () => carousel.prev())
			.on('click', '.js-carousel-thumbnail', handleCarouselThumbnailClick);
	}

	function setElements() {
		$zoomControl = $('.photo-preview-zoom-control');
	}

	function init(startIndex) {
		setElements();

		new PreviewGallery({
			selector: $('.sufficient-product-carousel-navigation'),
			asNavFor: $('.sufficient-product-photo-preview'),
			startIndex,
			perPage: 5,
			onInit: function() {
				onCarouselInit.call(this, startIndex);
			}
		});
	}

	return {
		init: init
	};
});
