/**
 * Moduł wykonuje operacje na koszyku
 */
app.service('cartManager', function($global, cartBox, broker, front, cookie, callback, modal, loading, goBack, userPreferences, warrantyEncouragement, warrantyQuestionnaire, pageConfig, log, productInteractionListener, console, message) {

	var addedPartnerSubscriptions = [];

	/**
	 * Tablica obiektów
	 * 	let subscriptionService = {
	 * 		serviceId: Number,
	 * 		groupId: Number
	 * 	};
	 */
	let subscriptionServices = [];

	function addService(options) {
		var data = {
			serviceId: options.serviceId
		};

		data.action = 'ADD_PRODUCT_SERVICE';
		data.productId = options.productId;
		data.addedFrom = options.addedFrom || 'PRODUCT_CARD';

		log.event({
			id : 'tocart',
			f03: options.addedFrom,
			f04: options.productPLU,
			f05: 'uslugi',
			f06: '',
			f07: front.page()
		});

		$.ajax({
			url: '/cart-item-operation.ltr',
			method: 'post',
			data:data,
			success: function(result) {
				if (result.status=='SUCCESS') {
					cartBox.refresh(options.serviceName);
				} else {
					failModalMessage(result.reason);
					cartBox.refresh();
				}
			},
			error: function() {}
		});
	}

	function removeService(options) {
		var data={
			serviceId: options.serviceId
		};
		if(options.mainCartItemId){
			data.action = 'REMOVE_MAIN_CART_ITEM_SERVICE';
			data.mainCartItemId = options.mainCartItemId;
		}else{
			data.action='REMOVE_PRODUCT_SERVICE';
			data.productId = options.productId;
		}
		$.ajax({
			url: '/cart-item-operation.ltr',
			method: 'post',
			data: data,
			success: function() {
				cartBox.refresh(options.serviceName);
			},
			error: function() {}
		});
	}
	function getAddedPartnerSubscriptions(){
		return [].concat(addedPartnerSubscriptions);
	}

	function addPartnerSubscription(options, callback) {
		loading.start();
		$.ajax({
			url:'/add-partner-subscriptions-to-cart.ltr',
			method: 'POST',
			dataType: 'json',
			data:{
				productId: options.productId,
				partnerSubscriptionId: options.partnerSubscriptionId,
				relatedPromotionIdentifier: options.relatedPromotionIdentifier,
				cartCookie: cookie.get("euroCart")
			},success:function(json){
				loading.stop();
				if (json.status == 'FAIL') {
					failModalMessage(json.messages);
				}else{
					cartBox.refresh();
					if(callback){
						callback();
					}
				}
			},error: function(){
				modal.alert('Błąd dodawania usług partnerskich')
			}
		});
	}

	function removePartnerSubscription(options,callback) {
		cartBox.abortRefresh();
		$.ajax({
			url: '/remove-partner-subscription.ltr',
			method: 'POST',
			dataType: 'json',
			data: {
				partnerSubscriptionId: options.partnerSubscriptionId,
				productId : options.productId
			},
			cache: false,
			complete: function(json) {
				if (json.status == 'FAIL') {
					failModalMessage(json.messages);
				}else{
					cartBox.refresh();
					if(callback){
						callback();
					}
				}
			}
		});
	}

	function savePartnerSubscriptions(options){
		var element=options.partnerSubscription+'|'+options.group;
		if(options.checked){
			addedPartnerSubscriptions.push(element);
		}else{
			addedPartnerSubscriptions.splice(addedPartnerSubscriptions.indexOf(element),1);
		}
	}

	function addWarranty(options) {
		var data={
			warrantyId: options.warrantyId,
			warrantyPaymentType: options.warrantyPaymentType
		};
		if(!front.katalog){
			data.warrantyAddedFrom = options.warrantyAddedFrom || 'DEFAULT';
		}
		if(options.packageIdentifier){
			data.action = 'OVERWRITE_PACKAGE_WARRANTY';
			data.addedFrom = options.addedFrom || 'PRODUCT_CARD';
			data.packageIdentifier = options.packageIdentifier;
		}else if(options.relatedPromotionIdentifier) {
			data.action = 'OVERWRITE_RELATED_PROMOTION_WARRANTY';
			data.addedFrom = options.addedFrom || 'PRODUCT_CARD';
			data.relatedPromotionIdentifier = options.relatedPromotionIdentifier;
		}else{
			if(!front.katalog){
				data.action= 'OVERWRITE_PRODUCT_WARRANTY';
			}
			data.addedFrom = options.addedFrom || 'PRODUCT_CARD';
			data.productId = options.productId;
		}
		loading.start();
		$.ajax({
			url: front.katalog?'/add-warrantyCartItem.ltr':'/cart-item-operation.ltr',
			method: 'post',
			data: data,
			success: function(result) {
				loading.stop();
				if (result.status=='SUCCESS') {
					cartBox.refresh(options.warrantyName);
					if (typeof options.callback === "function") {
						options.callback();
					}
				}else{
					cartBox.refresh();
					failModalMessage(result.reason);
				}
			},
			error: function() {
				loading.stop();
				modal.alert('Błąd dodawania gwarancji do koszyka.');
			}
		});
	}

	function removeWarranty(options) {
		var data={
			warrantyId: options.warrantyId
		};
		if(options.mainCartItemId){
			data.action= 'REMOVE_MAIN_CART_ITEM_WARRANTY';
			data.addedFrom = options.addedFrom || 'PRODUCT_CARD'
			data.mainCartItemId = options.mainCartItemId;
		}else{
			data.action= 'REMOVE_PRODUCT_WARRANTY';
			data.addedFrom = options.addedFrom || 'PRODUCT_CARD';
			data.productId = options.productId;
		}
		loading.start();
		$.ajax({
			url: front.katalog ? '/remove-warrantyCartItem.ltr' : '/cart-item-operation.ltr',
			method: 'post',
			data: data,
			success: function(response) {
				loading.stop();
				if (response.status === 'FAIL') {
					failModalMessage(response.messages);
					cartBox.refresh();
				}
				else {
					cartBox.refresh(options.warrantyName);
					if (typeof options.callback === "function") {
						options.callback();
					}
				}
			},
			error: function(html) {
				loading.stop();
				modal.alert('Błąd usuwania gwarancji z koszyka.');
			}
		});
	}
	function failModalMessage(code, callback = function () { }) {
		if (!code) {
			return;
		}
		if (typeof (code) === 'object') {
			code = code[0];
		}
		if (code.length && code.startsWith('msg:')) {
			message.alert(code, callback);
		} else {
			modal.alert(code, callback);
		}
	}

	function addToCartFromInstalment(productId, instalment, instalmentNumber, paymentType, delivery, addedFrom, productPlu, clientMaxInstalmentWorth, outletCategoryId, warranty, warrantyAddedFrom) {
		addProduct({
			productId: productId,
			productPLU: productPlu,
			isInstalment: true,
			instalment: instalment,
			instalmentNumber: instalmentNumber,
			paymentType: paymentType,
			delivery: (delivery) ? delivery : 'HOME',
			addedFrom: addedFrom,
			clientMaxInstalmentWorth: clientMaxInstalmentWorth,
			outletCategoryId,
			warranty: warranty,
			warrantyAddedFrom: warrantyAddedFrom
		});
	}

	function checkWarranties() {
		var $checkedWarranty = $('#warranties input.js-add-warranty-checkbox:checked')

		if (!$checkedWarranty.length) return false;

		return {
			warranty: $checkedWarranty.val(),
			warrantyPaymentType: $checkedWarranty.data('warranty-payment-type')
		};
	}

	function addProduct(options) {
		function checkServices() {
			var services = [];
			$('#services input.js-add-service-checkbox:checked').each(function() {
				services.push($(this).val());
			});
			return services;
		}

		function checkPartnerSubscriptions(){
			if(addedPartnerSubscriptions.length){
				return addedPartnerSubscriptions.map(function(n){
					return n.split("|")[0];
				})
			}
			return false;
		}

		loading.start();
		console.log('ROZPOCZĘCIE DODAWANIA DO KOSZYKA');
		var url = '/addToCartJsonTool.ltr';
		var data = {};
		data.productId = options.productId;
		if(options.outletLocal){
			url = '/add-outlet-product-to-cart.ltr';
		}
		if (options.outletCategoryId !== undefined) {
			data.outletCategoryId = options.outletCategoryId;
		}
		if (options.outletProductId !== undefined) {
			data.outletProductId = options.outletProductId;
		}
		if (options.relatedPromotionServiceId) {
			url = '/add-related-promotion-to-cart.ltr';
			if (options.replaceProduct) {
				data.replaceProduct = true;
			}
			data.relatedPromotionServiceId = options.relatedPromotionServiceId;
		}
		if (options.button) {
			data.button = options.button;
		}
		if (options.partnerSubscriptionsIds) {
			data.partnerSubscriptionsIds = options.partnerSubscriptionsIds;
		}
		if (options.outletState) {
			UA.addToCart(data.productId, {
				dimension45: 'Outlet',
				dimension46: options.outletState
			});
		} else {
			UA.addToCart(data.productId, {
				dimension45: 'Nowy',
				dimension46: 'Nowy'
			});
		}
		if (checkServices()) {
			data.services = checkServices();
		}
		if(options.warranty===undefined && checkWarranties()) {
			const { warranty, warrantyPaymentType } = checkWarranties();
			data.warranty = warranty;
			data.warrantyPaymentType = warrantyPaymentType;
		}
		if (checkPartnerSubscriptions()) {
			data.partnerSubscriptionsIds = checkPartnerSubscriptions();
		}
		if(options.warranty!==undefined) {
			data.warranty = options.warranty;
		}

		if(options.services) {
			data.services = options.services;
		}
		if(options.warranty) {
			data.warranty = options.warranty;
		}
		if(options.warrantyPaymentType) {
			data.warrantyPaymentType = options.warrantyPaymentType;
		}
		if (options.warrantyAddedFrom) {
			data.warrantyAddedFrom = options.warrantyAddedFrom;
		}
		if (options.delivery) {
			data.delivery = options.delivery;
		}
		if (options.addedFrom) {
			data.addedFrom = options.addedFrom;
		}
		if (options.paymentType) {
			data.paymentType = options.paymentType;
		}
		data.cartCookie = cookie.get("euroCart");
		if (options.shopCode) {
			data.shopCode = options.shopCode;
		}
		if (options.isInstalment) {
			data.isInstalment = options.isInstalment;
		}
		if (options.instalment) {
			data.instalment = options.instalment;
		}
		if (options.instalmentNumber) {
			data.instalmentNumber = options.instalmentNumber;
		}
		if(options.clientMaxInstalmentWorth) {
			data.clientMaxInstalmentWorth = options.clientMaxInstalmentWorth;
		}
		if (options.showWarrantyEncouragement !== undefined) {
			data.showWarrantyEncouragement = options.showWarrantyEncouragement;
		}
		if (options.toCartViewEnabled !== undefined) {
			data.toCartViewEnabled = options.toCartViewEnabled;
		}
		if (options.voucherCode !== undefined) {
			data.voucherCode = options.voucherCode;
		}
		if (options.productPLU) {
			data.productPLU = options.productPLU;
			addProductLogEvent(options);
			if(front.page() === 'product-card'){
				UA.doubleClick(options.productPLU, 'dc-prod-tocart-kp');
			}
			if(front.page() === 'comparison'){
				UA.doubleClick(options.productPLU, 'dc-tocart-sp');
			}
			log.recommendationsForAWS({
				productPlu: options.productPLU,
				eventCategory: options.addedFrom,
				eventAction: 'Dodanie do koszyka'
			});
		}
		if(front.katalog) {
			data.wcShopCode = pageConfig.getData('wc').shopCode;

			if (subscriptionServices.length) {
				data.subscriptionServices = subscriptionServices.map(({serviceId}) => serviceId);
			}
			if (data.warranty && data.productPLU) {
				var warrantyToCartEventData = {
					id: 'to_cart',
					f03: 'product_card',
					f04: data.warranty,
					f05: 'warranty',
					f06: data.productPLU,
					f07: 'warranty_details',
					f08: 'button',
					f09: '',
					f10: data.isInstalment ? 'installment' : 'ANY', // w eK brak zdefiniowanego warrantyPaymentType na KP
				}
				log.event(warrantyToCartEventData);
			}
		}


		productInteractionListener.onProductAddedToCart(options.productId);

		/*	dopytać
				// nadpisujemy kod rabatowy najlepszym dostepnym
				if (www.productsList && www.productsList.bestVoucherEnable) {
					if (typeof getBestVoucherCode == 'function') {
						var voucherCode = getBestVoucherCode(obj.productId);
						if (voucherCode)data.voucherCode = voucherCode;
					}
				}

		*/

		$.ajax({
			url: url,
			dataType: 'json',
			type: 'POST',
			cache: false,
			data: data,
			success: function(json) {
				loading.stop();
				if (json.addToCartPossible) {
					if (json.blockedCountry) {
						UA.interaction('Geoblocking', 'Unlocked', 'Cart');
					}
					addToCardSuccess(json, data);
				} else {
					if (json.blacklistConflict && json.cannotAddProductReasons) {
						let content = json.cannotAddProductReasons.map(function (e) { return '<li>' + e + '</li>' }).join("");
						modal.generate({
							id: 'cannot-add-product-reasons',
							content: `<h2>Produkt nie został dodany do koszyka</h2><p>Wybrany element <span>${json.justAdded}</span> nie pasuje do elementów znajdujących się już w koszyku.</p><p><strong>Prosimy o zwracanie uwagi na:</strong></p><ul>${content}</ul>`,
							buttons: [
								{
									text: "Ok",
									cssClass: 'btn-primary',
									click: function () {
										modal.close();
									}
								}
							]
						});
					} else {
						failModalMessage(json.cannotAddProductMessage);
						if (json.blockedCountry) {
							UA.interaction('Geoblocking', 'Locked', 'Cart');
						}else if (options.outletState) {
							UA.interaction('Komunikat błędu', 'Outlet', 'Stan produktu', false);
						}
					}
				}
			},
			error: function() {
				loading.stop();
				if(front.katalog){
					modal.alert('Operacja nie udała się. Odśwież stronę i spóbuj ponownie. Jeśli błąd sie powtórzy, prosimy zgłoś go kierownikowi sklepu.');
				}else{
					modal.alert('Operacja nie udała się. Odśwież stronę i spóbuj ponownie. Jeśli błąd sie powtórzy, prosimy zgłoś go obsłudze sklepu.');
				}
			}
		});
	}

	function addProductLogEvent(options){
		const frontPage = front.page();
		log.event({
			id: 'tocart',
			f03: options.addedFrom,
			f04: options.productPLU,
			f05: options.instalment ? 'raty' : '',
			f06: '',
			f07: frontPage
		});

		let toCartEventData = {
			id: 'to_cart',
			f03: frontPage,
			f04: options.productPLU,
			f05: 'product',
			f06: '',
			f07: 'leaf',
			f08: 'button',
			f09: options.outletState ? 'outlet' : 'new',
			f10: options.isInstalment ? 'installment' : 'cash',
			f23: 'desktop'
		}
		if(options.addedFrom && options.addedFrom == 'WATCHED_PRODUCTS'){
			toCartEventData.f03 = 'kme';
			// zalogowany uzytkownik ma watched_products, a niezalogowany recently_watched
			toCartEventData.f07 = options.pageSelector && options.pageSelector == 'LAST_VISITED' ? 'recently_watched': 'watched_products';
		}
		if(options.addedFrom && options.addedFrom == 'COMPARASION'){
			toCartEventData.f03 = 'comparison';
		}
		if(options.addedFrom && options.addedFrom == 'PRODUCT_CARD'){
			toCartEventData.f03 = options.addedFrom.toLowerCase();
			toCartEventData.f07 = 'product_details';
		}
		if(options.addedFrom && options.addedFrom == 'PRODUCT_LIST' && front.katalog){
			toCartEventData.f03 = options.addedFrom.toLowerCase();
		}
		if(frontPage == 'article'){
			toCartEventData.f03 = 'articles';
		}

		log.event(toCartEventData);

		if(options.addedFrom && options.addedFrom == 'PRODUCT_CARD'){
			if(options.services && options.services.length){
				log.event({
					id: 'Sekcja_uslug' + (options.instalment ? '_raty' : ''),
					f03: document.location.href,
					f04: options.services.join(',')
				});
			}
			if(options.partnerSubscriptionsIds && options.partnerSubscriptionsIds.length){
				log.event({
					id: 'Sekcja_uslugi_partnerskie' + (options.instalment ? '_raty' : ''),
					f03: document.location.href,
					f04: options.partnerSubscriptionsIds.join(',')
				});
			}
			if(options.warranty && options.warrantyPaymentType){
				log.event({
					id: 'Sekcja_gwarancji' + (options.instalment ? '_raty' : ''),
					f03: document.location.href,
					f04: options.warrantyPaymentType == 'POSTPONED' ? 'Płatność miesięczna' : 'Płatność jednorazowa',
					f05: options.warranty
				});
			}
		}
	}
	function addToCardSuccess(json, obj) {
		callback.run('logAddToCardSuccessEvent', $.extend(true, json, obj));
		if (json.encouragementTypeToShow === "WARRANTY" && $('#cart-box').length) {
			cartBox.refresh();
			warrantyEncouragement.load(json, obj, function(params) {
				var warrantyToCartEventData = {
					id: 'to_cart',
					f03: front.page(),
					f04: params.warrantyId,
					f05: 'warranty',
					f06: obj.productPLU,
					f07: 'warranty_box',
					f08: 'fancybox',
					f09: '',
					f10: params.warrantyPaymentType == 'POSTPONED' ? 'installment' : params.warrantyPaymentType == 'ONE_TIME' ? 'cash' : 'subscription' ,
					f23: 'desktop'
				}
				if(obj.addedFrom == 'WATCHED_PRODUCTS') {
					params.warrantyAddedFrom = 'ENCOURAGEMENT_PRODUCT_CARD_ADD_TO_CART';
					warrantyToCartEventData.f03 = 'kme';
				}else if(obj.addedFrom == 'PRODUCT_LIST') {
					params.warrantyAddedFrom = 'ENCOURAGEMENT_LISTING'
				}else if(obj.addedFrom == 'PRODUCT_CARD') {
					params.warrantyAddedFrom = obj.instalmentNumber ? 'ENCOURAGEMENT_PRODUCT_CARD_ADD_TO_CART_INSTALLMENT' : 'ENCOURAGEMENT_PRODUCT_CARD_ADD_TO_CART';
					warrantyToCartEventData.f03 = 'product_card';
				}else if(obj.addedFrom == 'COMPARATION') {
					params.warrantyAddedFrom = 'ENCOURAGEMENT_COMPARATION_ADD_TO_CART';
					warrantyToCartEventData.f03 = 'comparison';
				}
				if (warrantyToCartEventData.f03 == 'article') {
					warrantyToCartEventData.f03 = 'articles'
				}
				log.event(warrantyToCartEventData);
				addWarranty(params);
			}, function(){
				removeWarranty(params);
			});
		} else {
			if (!json.redirectUrl) {
				cartBox.refresh(json.justAdded);
				if (modal.isOpen('shops-modal')) {
					modal.close();
				}
			} else {
				goBack.setRefPage();
				loading.redirect(json.redirectUrl);
			}
		}
	}

	function addDynamicSuite(productId, packageElements) {
		loading.start();

		let data = {
			productId,
			packageElements
		};

		$.ajax({
			url: "/add-product-package-to-cart.ltr",
			method: "POST",
			data
		}).then(response => {
			if (response.addToCartPossible) {
				addToCardSuccess(response, data)
			} else {
				failModalMessage(response.cannotAddProductMessage);
			}
		})
		.fail(() => modal.alert("Błąd dodawania zestawu do koszyka."))
		.always(() => loading.stop());
	}

	function simpleAddToCart(ajaxData, callback, logEventData = {}) {
		var data = $.extend(true, {
			productId: undefined
		}, ajaxData);
		if (logEventData) {
			log.event(logEventData);
		}
		UA.addToCart(data.productId);
		cartBox.abortRefresh();
		$.ajax({
			url: '/add-product-to-cart.ltr',
			method: 'POST',
			data: data,
			cache: false,
			success: function(result) {
				if (result.status=='SUCCESS') {
					if (callback) {
						callback();
					}
				} else {
					failModalMessage(result.reason);
				}
				cartBox.refresh();
			},error:function(){

			}
		});
	}

	function simpleRemoveFromCart(ajaxData, callback, logEventData = {}) {
		var data = $.extend(true, {
			productId: undefined
		}, ajaxData);
		if (logEventData) {
			log.event(logEventData);
		}
		UA.removeFromCart(data.productId);
		cartBox.abortRefresh();
		$.ajax({
			url: '/remove-product-from-cart.ltr',
			method: 'POST',
			cache: false,
			data: data,
			complete: function() {
				if (callback) {
					callback();
				}
				cartBox.refresh();
			}
		});
	}

	function removeMainCartItem(ajaxData, callback) {
		cartBox.abortRefresh();
		$.ajax({
			url: '/remove-main-cart-item-from-cart.ltr',
			method: 'POST',
			cache: false,
			data: ajaxData,
			complete: function() {
				if (callback) {
					callback();
				}
				cartBox.refresh();
			}
		});
	}

	function checkRelatedProducts(relatedProducts) {	//jeżeli przyjdzie relatedProducts to sprawdzamy przypisane akcesoria do wskazanego produktu i produkty z koszyka
		var items = cartBox.getElements();
		if(relatedProducts){
			items = items.concat(cartBox.getAccessories(relatedProducts));
		}
		$('input[name=accessory]').each(function() {
			var $element = $(this);
			$element.removeAttr('disabled');
			$element.closest('.accessory-item').removeClass('loading');
			if ($.inArray(parseInt($element.val()), items) > -1) {
				$element.prop('checked', true).closest('.accessory-item').addClass('added-to-cart');
			} else {
				$element.prop('checked', false).closest('.accessory-item').removeClass('added-to-cart');
			}
		});
	}

	function removeCartItemOrAccessory(ajaxData){
		UA.removeFromCart(ajaxData.accessoryProductId);
		cartBox.abortRefresh();
		$.ajax({
			url: '/remove-cartItem-or-accessory.ltr',
			method: 'POST',
			data: ajaxData,
			cache: false,
			complete: function() {
				cartBox.refresh();
			}
		});
	}

	function removeAccessoryFromProduct(ajaxData,callback){
		UA.removeFromCart(ajaxData.accessoryProductId);
		cartBox.abortRefresh();
		$.ajax({
			url: '/remove-accessory.ltr',
			method: 'POST',
			data: ajaxData,
			cache: false,
			complete: function(html) {
				cartBox.refresh();
				if(callback){
					callback();
				}
			}
		});
	}

	function addAccessoryToProduct(ajaxData,callback){
		UA.addToCart(ajaxData.accessoryProductId);
		cartBox.abortRefresh();
		$.ajax({
			url: '/add-accessory.ltr',
			method: 'POST',
			data: ajaxData,
			cache: false,
			success: function(result) {
				loading.stop();
				if (result.status == 'FAIL') {
					failModalMessage(result.messages);
					if(!ajaxData.addedFrom && ajaxData.addedFrom !== 'CART') {
						$('.js-add-accessory-to-cart-checkbox[value=' + ajaxData.accessoryProductId + ']').prop('checked', false).removeAttr('disabled');
					}
				} else {
					if(callback) {
						callback()
					}else{
						cartBox.refresh();
					}
				}
			},
			error:function(){
				loading.stop();
				modal.alert('Nieudane zapisanie koszyka');
			}
		});
	}
	function clickRelatedProducts(id, checked, addedFrom, productPLU, mainProduct) {
		if(id){
			var $elements = $('input[name=accessory]').filter('[value=' + id + ']');
			var $element = $elements.eq(0);
			$elements.prop('disabled', true);
			$elements.each(function() {
				$(this).closest('.accessory-item').addClass('loading');
			});
			if (!checked) {
				if(mainProduct){
					removeCartItemOrAccessory({
						accessoryProductId: $element.val(),
						mainProductId : mainProduct
					});
				}else{
					if (front.katalog) {

                        wcRemoveProduct($element.val());
					} else {

						simpleRemoveFromCart({
							productId: $element.val()
						});
					}
				}
			} else {
				productInteractionListener.onProductAddedToCart(id);
				var newToCartEventData = {
					id: 'to_cart',
					f03: front.page(),
					f04: $element.data('plu'),
					f05: 'akc',
					f06: productPLU,
					f07: 'accessory_card',
					f08: 'button',
					f09: 'new',
					f10: 'cash',
				}
				if (newToCartEventData.f03 == 'product-card') {
					newToCartEventData.f03 = 'product_card'
					newToCartEventData.f07 = 'accessory_card';
				}
				if (newToCartEventData.f03 == 'to-cart') {
					newToCartEventData.f03 = 'cart_step_0'
					newToCartEventData.f07 = 'accessory_card';
				}
				if(mainProduct){
					addAccessoryToProduct({
						accessoryProductId: $element.val(),
						mainProductId : mainProduct
					});
					log.event(newToCartEventData);
				}else{
					simpleAddToCart({
						productId: $element.val(),
						addedFrom: addedFrom
					});
					log.event({
						id: 'tocart',
						f03: addedFrom,
						f04: $element.data('plu'),
						f05: 'AKC',
						f06: productPLU,
						f07: front.page()
					});
					log.event(newToCartEventData);
					log.recommendationsForAWS({
						productPlu: $element.data('plu'),
						eventCategory: addedFrom,
						eventAction: 'Dodanie akcesoria'
					});
					UA.doubleClick($element.data('plu'),'dc-acc-tocart-kp');
				}
			}
		}
	}

	function wcRemoveWarranty(productId) {
		console.log('USUWANIE GWARANCJI');
		$.ajax({
			url:'/remove-warrantyCartItem.ltr',
			type:'POST',
			data:{
				productId:productId
			},
			success:function(json){
				if (json.status && json.status === 'FAIL') {
					failModalMessage(json.messages, function(){
						cartBox.refresh();
					});
				}else{
					cartBox.refresh();
				}

			}
		});
	}

	function wcRemoveProduct(productId) {
		console.log('USUWANIE PRODUKTU');
		UA.removeFromCart(productId);
		$.ajax({
			url:'/remove-cartItem.ltr',
			type:'POST',
			data:{
				productId:productId,
				removeAccessories: false
			},
			success:function(json){
				if (json.status && json.status === 'FAIL') {
					failModalMessage(json.messages, function(){
						cartBox.refresh();
					});
				}else{
					cartBox.refresh();
				}
			}
		});
	}
	function removeItemFromCart(e) {
		const $item = $(this);
		const type = '' + $item.data('item-type');
		const currentId = '' + $item.data('item-id');
		const $items = $('#cart-box .items');
		const $focus = $('#cart-box #cart-focus');
		$items.addClass('loading');
		$focus.focus().click();
		const productId = $item.data('product-id');
		const mainCartItemId = $item.data('main-cart-item-id');
		const productCode = $item.data('product-code');

		switch( type ) {
			case 'warranty':
				if(front.katalog){
					wcRemoveWarranty(productId);
				}else {
					var data = { warrantyId: currentId, productId: productId, mainCartItemId: mainCartItemId };
					removeWarranty( data, function() {
						$items.removeClass('loading');
					});
				}
			break;
			case 'service':
				var data = { serviceId: currentId, productId: productId, mainCartItemId: mainCartItemId };
				removeService( data, function() {
					$items.removeClass('loading');
				});
			break;
			case 'partner-subscription':
				removePartnerSubscription({
					productId: productId,
					partnerSubscriptionId: currentId,
				},function(){
					cartBox.refresh();
				});
			break;
			case 'subscription-service':
				removeSubscriptionServiceRequest(productId, currentId);
			break;
			case 'product':
				warrantyQuestionnaire.clearProductWarrantyQuestion(productCode);
				if(front.katalog){
					wcRemoveProduct(currentId);
				}else{
					var data = { productId: currentId };
					simpleRemoveFromCart( data, function() {
						$items.removeClass('loading');
					});
				}
			break;
			case 'main-cart-item':
				var data = { mainCartItemId: currentId };
				removeMainCartItem( data, function() {
					$items.removeClass('loading');
				} );
			case 'accessory':
				var data = {
					accessoryProductId: currentId,
					mainProductId : productId
				};
				removeAccessoryFromProduct( data,function() {
					$items.removeClass('loading');
				});
			break;
			default:
			break;
		}
	}

	function setEvents() {
		$('#cart-box').on('click', '.js-remove-item', removeItemFromCart);
	}
	function init() {
		setEvents();
	}
	function convertProductPluToProductId(productPlu) {
		return $.ajax({
			url: '/product-code-to-id.ltr',
			dataType: 'json',
			data: {
				productPlu
			}
		});
	}

	function saveBasket(callback,failback){
		loading.start();
		$.ajax({
			url:'/zapisz-koszyk.ltr',
			type:'POST',
			data:{
				//
			},
			success:function(){
				if(callback){
					callback();
				}else{
					loading.stop();
					modal.alert('Koszyk zapisano');
				}

			},
			error:function(){
				if(failback){
					failback();
				} else {
					loading.stop();
					modal.alert('Nieudane zapisanie koszyka');
				}

			}
		});
	}

	function addSubscriptionService(service) {
		subscriptionServices = [...getSubscriptionServices().filter(item => item.groupId !== service.groupId), service];
	}

	function removeSubscriptionService(serviceId) {
		subscriptionServices = getSubscriptionServices().filter(item => item.serviceId !== serviceId)
	}

	function getSubscriptionServices() {
		return subscriptionServices;
	}

	function setSubscriptionServices(aSubscriptionServices) {
		return subscriptionServices = aSubscriptionServices;
	}

	/*
	* @returns Promise
	 */
	function addSubscriptionServiceRequest(productId, subscriptionServiceId) {

		const deferred = $.Deferred();

		loading.start();
		$.ajax({
			url: '/add-subscription-service-to-cart.ltr',
			method: 'POST',
			dataType: 'json',
			data: {
				productId,
				subscriptionServiceId,
				cartCookie: cookie.get("euroCart")
			}
		}).then(json => {
			if (json.status === 'FAIL') {
				failModalMessage(json.messages);
				deferred.reject();
			} else {
				cartBox.refresh();
				deferred.resolve();
			}
		}).fail(() => {
			modal.alert('Błąd dodawania usługi do koszyka.')
			deferred.reject();
		}).always(() => loading.stop());

		return deferred.promise();
	}

	/*
	* @returns Promise
	 */
	function removeSubscriptionServiceRequest(productId, subscriptionServiceId) {

		const deferred = $.Deferred();

		loading.start();
		$.ajax({
			url: '/remove-subscription-service.ltr',
			method: 'POST',
			dataType: 'json',
			data: {
				productId,
				subscriptionServiceId,
				cartCookie: cookie.get("euroCart")
			}
		}).then(json => {
			if (json.status === 'FAIL') {
				failModalMessage(json.messages);
				deferred.reject();
			} else {
				cartBox.refresh();
				deferred.resolve();
			}
		}).fail(() => {
			modal.alert('Błąd usuwania usługi z koszyka.');
			deferred.reject();
		}).always(() => loading.stop());

		return deferred.promise();
	}


	broker.on('ADD_TO_CART_BY_PLU', (e, params) => {
		const {productPlu} = params;

		convertProductPluToProductId(productPlu)
			.then(productId => {
				const productToAddData = $.extend(params, productId);

				addProduct(productToAddData);
			});
	});

	window.addToCartByPlu = params => {
		broker.trigger('ADD_TO_CART_BY_PLU', [ params ]);
	};

	$global('addProduct', addProduct);
	return {
		init: init,
		addProduct,
		addService,
		addPartnerSubscription,
		removePartnerSubscription,
		removeService,
		addWarranty,
		removeWarranty,
		checkRelatedProducts,
		clickRelatedProducts,
		refresh: cartBox.refresh,
		getElements: cartBox.getElements,
		getServices: cartBox.getServices,
		getWarranty: cartBox.getWarranty,
		getAccessories: cartBox.getAccessories,
		getPartnerSubscriptions: cartBox.getPartnerSubscriptions,
		savePartnerSubscriptions,
		getAddedPartnerSubscriptions,
		addToCartFromInstalment,
		addAccessoryToProduct,
		removeAccessoryFromProduct: removeAccessoryFromProduct,
		saveBasket,
		simpleAddProduct: simpleAddToCart,
		removeProduct: simpleRemoveFromCart,
		addDynamicSuite,
		setSubscriptionServices,
		addSubscriptionService,
		removeSubscriptionService,
		getSubscriptionServices,
		removeSubscriptionServiceRequest,
		addSubscriptionServiceRequest
	};
});
