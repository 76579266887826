wwwCatalog=false;
function wcAddProductsCriteriaParam(url) {return '';}
var www={
	appName:'ole',
	pageType:'',
	print:false,
	groupId:0,
	fancyOk:function(text){
		this.fancyOkCallback(text, function() {$.fancybox.close();});
	},
	fancyOkCallback:function(body, onclick) {
		var t = ' <div class="w350">'
			+ '<div class="f14 bold tcenter">'
			+ body
			+ '</div>'
			+ '<div class="clear"></div>'
			+ '<div class="buttonA h30 mT10">'
			+ '<a href="#" class="ok btn btn-primary">OK</a>'
			+ '<div class="clear"></div>'
			+ '</div>'
			+ '</div>';
		var option = {
			autoDimensions : true,
			onComplete : function() {
				$.fancybox.resize();
				$('#fancybox-content .ok').click(function() {
					onclick();
				});

			}
		};
		$.fancybox(t, option);
	},
	events:{
		shopEventLogger:function(obj){
			app.logEvent(obj);
		}
	}
};
messageShopValidator="Wybierz Punkt Odbioru Osobistego, w którym chcesz odebrać Towary.";

function previewStart() {
	var container = $('<div id="personPopupContainer">' + '<table width="" border="0" cellspacing="0" cellpadding="0" align="center" class="personPopupPopup">'
		+ '<tr>' + '   <td class="left">&nbsp;</td>' + '   <td><div id="personPopupContent"></div></td>' + '   <td class="right">&nbsp;</td>' + '</tr>'
		+ '</table>' + '</div>');
	$('body').append(container);
	window.container = container;
	jQuery('#personPopupContainer').mouseover(function() {
		if (hideTimer)
			clearTimeout(hideTimer);
		$('a.hover').trigger('hover');
	});

	// Hide after mouseout
	jQuery('#personPopupContainer').mouseout(function() {
		if (hideTimer)
			clearTimeout(hideTimer);
		hideTimer = setTimeout(function() {
			container.css('display', 'none');
		}, hideDelay);
	});
}

function start(print) {
	try {
		// CHECKBOXY I RADIO SAFARI
		checkbox('');

		$('body').append('<div id="toolPgr"><div class="top"></div><div class="bottom"><div class="ico"></div></div></div>');
		$('#toolPgr').hover(function() {
			$(this).addClass('hover');
		}, function() {
			$('#toolPgr').removeClass('hover');
			closeToolBox();
		});


		$(document).on('click','.js-privacy-fancybox',function(event){
			var url = this.getAttribute("href");
			event.preventDefault();
			privacyFancybox(url);
		});

		getAdditionalPageContent(); //dodatkowe skrypty po autoryzacji
		if(print=='true'){		//funkcje które mają się pojawić tylko na princie
			replaceLink();
			if(www.pageType=='product'){

			}else{
				if($('#printButton a').length){
					setTimeout("$('#printButton a').click()",2000);
				}else setTimeout("window.print()",2000);
			}
		}else	{		//funkcje które nie powinny wyświetlić się przy drukowaniu
			if(print!='preview'){
				$('#newsleterEmail').keyup(function(event) {
					if (event.keyCode == 13)
						newsletterSend();
				});
				$('h4.title').each(function() {
					if ($(this).height() > 32)
						$(this).addClass("title-long");
				});
				errorBox();
				$('.tooltip').tooltip({
					track : true,
					showURL : false,
					left : -5
				});
				fancyCmsLayer();
				$('.int').keyfilter(/[\d]/);
			}
			fancybox();
		}
	}catch(e){console.log(e)}
}

function get503Page() {

	$.ajax({
		url : '/upload_module/inne/kolejka-body.html',
		success : function(html) {
			$("body").html(html);
		}
	});
}


function mapStart(params) {

	var myLatlng = new google.maps.LatLng(params.latitude, params.longitude);
	var mapOptions = {
		zoom : 14,
		center : myLatlng,
		mapTypeId : google.maps.MapTypeId.ROADMAP
	};
	var map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);

	var marker = new google.maps.Marker({
		position : myLatlng,
		map : map,
		animation : google.maps.Animation.DROP,
		title : $('#name' + params.code).html()
	});
}
function googleMaps(params) {
	var html = '<div id="map_canvas" style="width:800px; height:500px"></div><div class="mapsInfo">' + $('#map' + params.code).html() + '</div>';
	$.fancybox(html, {
		autoDimensions : true,
		onComplete : function() {
			mapStart(params);
		}
	});
}



function logAddToCardSuccessEvent(obj){

	//nadpisywane przez Euro
}
